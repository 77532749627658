import { message } from 'antd'
import Api, { makeRequest } from 'api/apiv2'
import Apiv1 from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { sortByDate, sortByString } from 'utils'
import { fundraiserActions } from './fundraiserReducer'

export const addFundraiser = async (data, user, defaultFyler) => {
  let updatedData = data

  // for flyer file as it can 1 or more than 1
  if (updatedData.flyer && updatedData.flyer.length) {
    const FlyerFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.flyer.length; i += 1) {
      const file = updatedData.flyer[i]
      if (!file?.url && !file?.fileName) {
        const flyerFile = await uploadFile(file)
        if (flyerFile && flyerFile?.fileName) {
          FlyerFiles.push({
            fileName: flyerFile?.fileName,
            fileMimeType: flyerFile?.fileMimeType,
          })
        }
      } else {
        const index = file?.uid || i
        if (defaultFyler.length > index) {
          const defaultFile = defaultFyler[index]
          FlyerFiles.push(defaultFile)
        }
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      flyer: FlyerFiles,
    }
  }

  // for volunteer opportunity banner images
  if (updatedData.voBannerImages && updatedData.voBannerImages.length) {
    const BannerFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.voBannerImages.length; i += 1) {
      const voBannerImage = updatedData.voBannerImages[i]
      if (!voBannerImage?.url && !voBannerImage?.fileName) {
        const flyerFile = await uploadFile(voBannerImage)
        if (flyerFile && flyerFile?.fileName) {
          BannerFiles.push({
            fileName: flyerFile?.fileName,
            fileMimeType: flyerFile?.fileMimeType,
          })
        }
      }
      //  else { // AS NO UPDATE CASE HERE FOR VOLUNTEER OPPORTUNITY
      //   const index = voBannerImage?.uid || i
      //   if (defaultFyler.length > index) {
      //     const defaultFile = defaultFyler[index]
      //     BannerFiles.push(defaultFile)
      //   }
      // }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      voBannerImages: BannerFiles,
    }
  }

  updatedData = {
    ...updatedData,
    createdBy: user.email,
    refLink: `${window.location.origin}/presenter/accept`,
    userName: user.id,
  }
  if (updatedData.isSelfPresent) {
    updatedData = {
      ...updatedData,
      presenter: 'Myself',
      presenterName: `${user.firstname} ${user.lastname}`,
      presenterEmail: user.email,
      presenterPhoneCode: user.phone,
      presenterMobileNumber: user.phone,
    }
  }

  // Auction Item Image Upload
  if (updatedData.auctionItemsList) {
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.auctionItemsList.length; i += 1) {
      const item = updatedData.auctionItemsList[i]
      if (item?.itemImage?.originFileObj) {
        const itemFile = await uploadFile(item.itemImage.originFileObj)
        item.itemImage = {
          fileName: itemFile.fileName,
          fileMimeType: itemFile.fileMimeType,
        }
      }
      updatedData.auctionItemsList[i] = item
    }
    /* eslint-disable no-await-in-loop */
  }
  delete updatedData.recaptcha
  const response = await makeRequest(
    'post',
    '/admin/fundraisers',
    { organization: data?.organizationId },
    updatedData,
  )
  return response
}

export const updateFundraiser = async (data, user, defaultFyler) => {
  let updatedData = data
  // for Fyler file as it can 1 or more than 1
  if (updatedData.flyer && updatedData.flyer.length) {
    const FlyerFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.flyer.length; i += 1) {
      const file = updatedData.flyer[i]
      if (!file?.url && !file?.fileName) {
        const flyerFile = await uploadFile(file)
        if (flyerFile && flyerFile?.fileName) {
          FlyerFiles.push({
            fileName: flyerFile?.fileName,
            fileMimeType: flyerFile?.fileMimeType,
          })
        }
      } else {
        const index = file?.uid || i
        const defaultFile = defaultFyler[index]
        FlyerFiles.push(defaultFile)
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      flyer: FlyerFiles,
    }
  }

  if (updatedData.isSelfPresent) {
    updatedData = {
      ...updatedData,
      presenter: 'Myself',
      presenterName: `${user.firstname} ${user.lastname}`,
      presenterEmail: user.email,
      presenterPhoneCode: user.phone,
      presenterMobileNumber: user.phone,
    }
  }

  updatedData = {
    ...updatedData,
    updatedBy: user.email,
    refLink: `${window.location.origin}/presenter/accept`,
    userName: user.id,
  }
  const response = await Api.put(
    `/admin/fundraisers/${updatedData.iD}`,
    updatedData,
  )
  return response
}

export const getOrganizationFundraisers =
  (organizationId, sortKey) => (dispatch) => {
    dispatch(fundraiserActions.setLoading(true))
    Api.get(`/admin/fundraisers?organizationId=${organizationId}`)
      .then((res) => {
        const sortedData =
          sortKey === 'title'
            ? sortByString(res?.data, sortKey)
            : sortByDate(res?.data, sortKey)
        dispatch(fundraiserActions.setFundraisers(sortedData))
        dispatch(fundraiserActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(fundraiserActions.setFundraisers([]))
        dispatch(fundraiserActions.setLoading(false))
      })
  }

export const updateFundraiserStatus = (id, payload) => (dispatch) => {
  dispatch(fundraiserActions.setLoading(true))
  Api.put(`admin/fundraisers/${id}/status`, payload)
    .then(() => {
      dispatch(fundraiserActions.setLoading(false))
      message.success(
        `Fundraiser ${
          payload.status === 0 ? 'Deactivated' : 'Activated'
        } Successfully`,
      )
    })
    .catch((error) => {
      console.log('error', error)
      message.error('Something Went Wrong')
      dispatch(fundraiserActions.setLoading(false))
    })
}

export const getFundraiserDetails = (id) => (dispatch) => {
  dispatch(fundraiserActions.setLoading(true))
  Api.get(`/admin/fundraisers/${id}`)
    .then((res) => {
      dispatch(fundraiserActions.setFundraiser(res.data))
      dispatch(fundraiserActions.setLoading(false))
    })
    .catch(() => {
      dispatch(fundraiserActions.setFundraiser(null))
      dispatch(fundraiserActions.setLoading(false))
    })
}

export const getFundraiser = async (id) => {
  const response = await Api.get(`/admin/fundraisers/${id}`)
  return response.data
}

export const getFeaturedFundraiser = async (organizationId) => {
  const response = await Api.get(
    `/admin/fundraisers/featured?organizationId=${organizationId}`,
  )
  return response.data
}

export const getSubscriberPaymentInformation =
  (organizationId) => (dispatch) => {
    Apiv1.get(`/event/account-info?organizationId=${organizationId}`)
      .then((paymentInformation) => {
        if (paymentInformation) {
          dispatch(
            fundraiserActions.setSubscriberStripeInfo({
              isConnected:
                paymentInformation.defaultCategory?.stripeID &&
                paymentInformation.defaultCategory?.stripeOnBoarded,
              stripeId: paymentInformation.defaultCategory?.stripeID,
            }),
          )

          dispatch(
            fundraiserActions.setSubscriberPaypalInfo({
              isConnected: !!paymentInformation.defaultCategory?.paypalID,
              paypalId: paymentInformation.defaultCategory?.paypalID,
            }),
          )
        }
        dispatch(fundraiserActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(fundraiserActions.setLoading(false))
      })
  }

export const getSubscriberMarkupInfo = (orgId) => (dispatch) => {
  Apiv1.get(`/admin/subsrciberMarkUpsByOrg/${orgId}`)
    .then((res) => {
      dispatch(
        fundraiserActions.setSubscriberMarkupInfo(
          Number(res?.results?.StripeMarkup) || 1,
        ),
      )
      dispatch(fundraiserActions.setLoading(false))
    })
    .catch(() => {
      dispatch(fundraiserActions.setLoading(false))
    })
}

export const getFundraiserAuctionItems = (id, setLoading) => (dispatch) => {
  if (setLoading) {
    setLoading(true)
  }
  Api.get(`admin/fundraisers/${id}/auction-items`)
    .then((res) => {
      const items = res?.data

      const condition = items && items.length > 0
      dispatch(fundraiserActions.setIsAuction(condition))

      const notEndedAuctionItems = items.filter(
        (item) => item.auctionEnded === false,
      )

      dispatch(
        fundraiserActions.setFundraiserAuctionItems(notEndedAuctionItems),
      )
      if (setLoading) {
        setLoading(false)
      }
    })
    .catch((error) => {
      console.log('error', error)
      if (setLoading) {
        setLoading(false)
      }
    })
}

export const updateFundraiserAuctionItemsView =
  (id, payload, setLoading) => (dispatch) => {
    setLoading(true)
    Api.put(`admin/fundraisers/${id}/auction-item-view-status`, payload)
      .then(() => {
        Api.get(`/admin/fundraisers/${id}`)
          .then((res) => {
            dispatch(fundraiserActions.setFundraiser(res.data))
            setLoading(false)
          })
          .catch((error) => {
            console.log('error', error)
            setLoading(false)
          })
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

export const getFundraiserControlScreenDetails = (id) => (dispatch) => {
  /* eslint-disable */
  // get Event Status
  const getStatus = (id) => (dispatch) => {
    Api.get(`/admin/fundraisers/${id}/presenter-stats`)
      .then((res) => {
        dispatch(fundraiserActions.setFundraiserStatus(res?.data))
        dispatch(fundraiserActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(fundraiserActions.setLoading(false))
      })
  }
  // get Table Tasks
  const getTableTasks = (id) => (dispatch) => {
    Api.get(`/admin/fundraisers/${id}/table-task`)
      .then((res) => {
        dispatch(fundraiserActions.setFundraiserTableTask(res?.data))
        dispatch(getStatus(id))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(fundraiserActions.setLoading(false))
      })
  }
  // get all Donations
  Api.get(`/admin/fundraisers/${id}/donations`)
    .then((res) => {
      if (res?.data) {
        const donations = res.data.sort(function compare(a, b) {
          var dateA = new Date(a.PaymentDate)
          var dateB = new Date(b.PaymentDate)
          return dateB - dateA
        })
        dispatch(fundraiserActions.setFundraiserDonations(donations))
        dispatch(getTableTasks(id))
      }
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(fundraiserActions.setLoading(false))
    })

  Api.get(`/admin/fundraisers/${id}/pending-cash`)
    .then((res) => {
      dispatch(fundraiserActions.setPendingCash(res?.data))
      dispatch(getTableTasks(id))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(fundraiserActions.setLoading(false))
    })

  Api.get(`/admin/fundraisers/${id}/seed-donations`)
    .then((res) => {
      dispatch(fundraiserActions.setSeedDonations(res?.data))
      dispatch(getTableTasks(id))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(fundraiserActions.setLoading(false))
    })

  Api.get(`/admin/fundraisers/${id}/auction-status`)
    .then((res) => {
      dispatch(fundraiserActions.setAuctionStatus(res?.data))
      dispatch(fundraiserActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(fundraiserActions.setLoading(false))
    })
}

export const getFundraiserDetailWithoutLoading = (id) => (dispatch) => {
  Api.get(`/admin/fundraisers/${id}`)
    .then((res) => {
      dispatch(fundraiserActions.setFundraiser(res.data))
    })
    .catch((error) => {
      console.log('error', error)
    })
}

export const startStopFundraiserApi = async (id, payload) => {
  const response = await Api.put(`/admin/fundraisers/${id}/manage`, payload)
  return response
}

export const startStopFundraisingApi = async (id, payload) => {
  const response = await Api.put(
    `/admin/fundraisers/${id}/fundraising-status`,
    payload,
  )
  return response
}

export const updateYoutubeLink = async (id, data) => {
  const response = await Api.put(`/admin/fundraisers/${id}/youtubelink`, data)
  return response
}

export const addFundraiserCashDonation = async (id, data) => {
  const response = await Api.post(`admin/fundraisers/${id}/cash-donation`, data)
  return response
}

export const getSoldItemDetails = (id, setLoading, setError) => (dispatch) => {
  setLoading(true)
  Api.get(`/admin/auctions/${id}`)
    .then((res) => {
      const soldItem = res?.data
      dispatch(fundraiserActions.setSoldItem(soldItem))
      dispatch(
        getSubscriberPaymentInformation(soldItem?.organizationId, setLoading),
      )
    })
    .catch((error) => {
      console.log('error', error)
      const errMessage =
        error?.response?.data?.message ||
        'Something went wrong in pledge detail'
      setError(errMessage)
      setLoading(false)
    })
}

export const addToAttendy = async (id, payload) => {
  const response = await Api.post(
    `/admin/fundraisers/${id}/mark-attendance`,
    payload,
  )
  return response
}

export const getAllPresenterFundraisers = (email) => (dispatch) => {
  dispatch(fundraiserActions.setLoading(true))
  Api.get(
    `admin/presenters/fundraisers?email=${email}
`,
  )
    .then((res) => {
      const reponseResult = res?.data?.sort((a, b) => {
        const dateA = new Date(a?.startDate)
        const dateB = new Date(b?.startDate)
        return dateB - dateA
      })
      dispatch(fundraiserActions.setFundraisers(reponseResult))
      dispatch(fundraiserActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(fundraiserActions.setLoading(false))
    })
}

export const acceptFundraiserInvite = (id, email) => (dispatch) => {
  dispatch(fundraiserActions.setLoading(true))
  Api.put(`/admin/fundraisers/${id}/accept`)
    .then(() => {
      message.success('Invite Accepted Successfully')
      dispatch(fundraiserActions.setLoading(false))
      dispatch(getAllPresenterFundraisers(email))
    })
    .catch(() => {
      dispatch(fundraiserActions.setLoading(false))
    })
}

export const rejectFundraiserInvite = (id, email) => (dispatch) => {
  dispatch(fundraiserActions.setLoading(true))
  Api.put(`/admin/fundraisers/${id}/reject`)
    .then(() => {
      message.success('Invite Rejected Successfully')
      dispatch(fundraiserActions.setLoading(false))
      dispatch(getAllPresenterFundraisers(email))
    })
    .catch(() => {
      dispatch(fundraiserActions.setLoading(false))
    })
}

export const getFundraiserDonations = (id) => (dispatch) => {
  Api.get(`/admin/fundraisers/${id}/donations`)
    .then((res) => {
      if (res?.data) {
        const donations = res.data.sort(function compare(a, b) {
          var dateA = new Date(a.PaymentDate)
          var dateB = new Date(b.PaymentDate)
          return dateB - dateA
        })
        dispatch(fundraiserActions.setFundraiserDonations(donations))
      }
    })
    .catch((error) => {
      console.log('error', error)
    })
}
