import React, { useEffect, useRef } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { Form, Input } from 'antd'
import { awsconfig } from 'configs'

const AutoCompleteAddress = ({
  form,
  name,
  label = 'Address',
  disabled,
  validationRules,
  value,
}) => {
  const antInputRef = useRef(null)

  const { ref: antRef } = usePlacesWidget({
    apiKey: awsconfig.google_api_key,
    onPlaceSelected: (place) => {
      form.setFieldValue('address', place?.formatted_address)
      form.validateFields(['address'])
    },
    options: {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'formatted_address', 'geometry'],
      types: ['address'],
    },
  })

  // To hanlde auto sumbiting of form on place selection
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault() // Prevent the default Enter key behavior
      }
    }

    if (antRef.current) {
      antRef.current.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (antRef.current) {
        antRef.current.removeEventListener('keydown', handleKeyDown)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    form.setFieldValue('address', value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <Form.Item
        name={name}
        label={`${label}`}
        colon={false}
        className="t-mr-2"
        rules={[validationRules]}
      >
        <Input
          placeholder={`Search ${label}`}
          ref={(c) => {
            antInputRef.current = c
            if (c) antRef.current = c.input
          }}
          disabled={disabled}
        />
      </Form.Item>
    </>
  )
}

export { AutoCompleteAddress }
